import React from "react";
import {FaLinkedin} from 'react-icons/fa'
import {FaGithub} from 'react-icons/fa';
import "/Users/isee/PERSO/mon-app-portfolio/src/CSS/contact.css";

import inpic from '/Users/isee/PERSO/mon-app-portfolio/src/image/in.png';
import git from '/Users/isee/PERSO/mon-app-portfolio/src/image/git.png';
import Projects from "./Projects";


// Hire me!, freelance Projects, remote job full/part time

export default function Contact() {
  return (
    <section id="contact" className="relative">
      <div className="container px-5 py-10 mx-auto flex sm:flex-nowrap flex-wrap">
        <div className="lg:w-2/3 md:w-1/2 bg-gray-900 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
        <h2 id="contact">Contact</h2>
          <div className="bg-gray-900 relative flex flex-wrap py-6 rounded shadow-md">
            <div className="containerproj">
              <p className="mt-1" id="contact"> isee.besombes@orange.fr <br />
              <br />
              +33 6 82 26 98 68<br />
              </p>
              <p></p>
              <p>
              <a id="malt" href="https://www.malt.fr/profile/iseebesombes">Work with me via Malt! <br /></a>
              </p>
              <p>
              <a id="contact" href="https://www.linkedin.com/in/isée-besombes/"> <FaLinkedin color="#0781FE" /> Join me on LinkedIn! <br /></a>
              </p>
              <p>
              <a id="git" href="https://github.com/IseeB"> <FaGithub color="#FFFFFF"/> Have a look to my GitHub! <br /></a>
              </p>
          </div>
          </div>
        </div>
      </div>
    </section>
  );
}