import React from "react";
import { motion, useAnimation } from "framer-motion";
import Gradient from 'rgt'

import "/Users/isee/PERSO/mon-app-portfolio/src/CSS/project.css";
import R from '/Users/isee/PERSO/mon-app-portfolio/src/image/34R.PNG';
import RS from '/Users/isee/PERSO/mon-app-portfolio/src/image/34RS.png';
import inp from '/Users/isee/PERSO/mon-app-portfolio/src/image/inpaint.png';
import un from '/Users/isee/PERSO/mon-app-portfolio/src/image/1.png';
import deux from '/Users/isee/PERSO/mon-app-portfolio/src/image/2.png';
import trois from '/Users/isee/PERSO/mon-app-portfolio/src/image/3.png';
import quatre from '/Users/isee/PERSO/mon-app-portfolio/src/image/4.png';
import clip from "/Users/isee/PERSO/mon-app-portfolio/src/image/clip.mov";

import scene4 from '/Users/isee/PERSO/mon-app-portfolio/src/image/scene4.png'
import vid4 from '/Users/isee/PERSO/mon-app-portfolio/src/image/vid4.mov'
import scene2 from '/Users/isee/PERSO/mon-app-portfolio/src/image/vid4.png'
import s3 from '/Users/isee/PERSO/mon-app-portfolio/src/image/s3.png'
import s1 from '/Users/isee/PERSO/mon-app-portfolio/src/image/s4.png'
import r2d from '/Users/isee/PERSO/mon-app-portfolio/src/image/2d.png'
import r3d from '/Users/isee/PERSO/mon-app-portfolio/src/image/3d.png'
import shape from '/Users/isee/PERSO/mon-app-portfolio/src/image/APP-DEMO.mov'

import {FaPython} from 'react-icons/fa'
import {FaReact} from 'react-icons/fa'
import {SiCplusplus} from 'react-icons/si'
import {SiMysql} from 'react-icons/si'
import {SiTensorflow} from 'react-icons/si'
import {SiPytorch} from 'react-icons/si'
import {SiJavascript} from 'react-icons/si'
import {DiCss3} from 'react-icons/di'
import {DiHtml5} from 'react-icons/di'
// https://react-icons.github.io/react-icons/search?q=pytorch

// https://www.timc.fr/isee-besombes

export default function Projects() {
  return (
    <section id="projects" className="text-gray-400 bg-gray-900 body-font">
      <h2 id="proj"> 
      <Gradient dir="left-to-right" from="rgba(5, 233, 165, 1)" to="rgba(3, 156, 128  ,1)"> PORTFOLIO </Gradient>
      </h2>

      <div className="container px-5 py-10 mx-auto text-center lg:px-40">
        <div className="flex flex-col w-full mb-20">

{/* First project #1.1 */}
        {/* <div className="containerproject">
        <div id="box" className="">
        <p id="project">
          <p id="title">
          URL Classifier for BOT Phishing (As a freelance Data Scientist)<br /> <FaPython color="#04B480" /> <SiTensorflow color="#04B480" />
          </p>
          Model made in python with tensorflow, it classifies requests to block bots attacks on several servers.<br />
          </p>
        </div>
        </div> */}

<div className="containerproject">
        <div id="box" className="">
        <p id="project">
          <p id="title">
          Python QT Application for 3D shapes visualization, positionning and rescaling (As a freelance Python Developer)<br /> <FaPython color="#04B480" />
          </p>
          The GUI has been made with QT as all the transformations algorithms were developed in Python. This application allows to place 3D shapes 
          on different points of a support and perform some rotations and scalings to adapt the object to the desired size. <br />
          <img src={shape} alt="shapescale" ></img>
          </p>
        </div>
        </div>

{/* First project #1 */}
        <div className="containerproject">
          <div id="box" className="one">
          <p id="project"> <p id="title"> This portfolio website <br /> <FaReact color="#04B480" /> <SiJavascript color="#04B480" /> 
          <DiCss3 color="#04B480" /> <DiHtml5 color="#04B480" />
          {/* 03A02E */}
          </p>
          As I started my experience as a Freelance Data Scientist/ Python developper I needed a way to expose my projects. This was the perfect 
          opportunity to develop, design and deploy a website for the first time.<br />
          And it was actually a great first project!
          </p>
          </div> 
        </div>

{/* Second project #2 */}
        <div className="containerproject">
          <div id="box" className="two">
          <p id="project">
          <p id="title"> Masters research project (6 months, full time), at <a id="project" href="https://twinsight-medical.com/orthotwin-eng/"> 
          Twinsight</a> and <a id="timc" href="https://www.timc.fr/biomeca"> TIMC Laboratory</a> <br />
          <FaPython color="#04B480" /></p>
          Data augmentation strategy for the training of a CNN capable of segmenting lower limb bones within CT scan images.<br />
          The strategy was based on :
          <ul>
            <li>A non-rigid registration method using the Elastix library for surface-surface registration,</li>
            <li>The creation of a statistical atlas of the right knee with the variations of the four bones together 
              (including the design of a GUI for variations' invesgation on the 3D surfaces),</li>
            <li>The generation of artificial CTs.</li>
          </ul>
          The error of segmentation has been devided by 2,5 with the addition of artificial images to the training dataset.<br />
          </p>
          {/* <img id="about" src={R} alt="me" height={540} width={380} ></img>
          <img id="about" src={RS} alt="me" height={540} width={380} ></img> */}
          {/* <video width="750" height="500" controls >
          <source src={clip} type="video/mp4"/>
          </video> */}
          </div>
        </div>

{/* Third project #3 */}
        <div className="containerproject">
        <div id="box" className="three">
        <p id="project"> <p id="title">
          Modelling seminar project (3 months, part time, 2nd year Master's project), at <a id="ljk" href="https://www-ljk.imag.fr/"> LJK Laboratory</a> <br />
          <FaPython color="#04B480" /> <SiTensorflow color="#04B480" /> <SiPytorch color="#04B480" />
          </p>
          Adaptation of an already existing 2D inpainting CNN model to B&W 2D images and to both RGB and B&W 3D images for 3D proteins' structures predictions.<br />
          The final goal was to adapt it to even more channels to represent the proteins structures in the time allotted we have stopped with 3D image 
          with different intensities. <br />
          <img src={r2d} alt="inpainting" ></img>
          <img src={r3d} alt="inpainting" ></img>
          </p>
        </div>
        </div>

{/* Fourth project #4 */}
        <div className="containerproject">
        <div id="box" className="five">
        <p id="project">
          <p id="title">
          Fingerprint acquisition (1st year Master's project) <br /> <SiCplusplus color="#04B480" />
          </p> 
          An image processing project which aimed to simulate and restore possible fingerprint acquisition anomalies. For example we could deblur an image,
          perform rotations, erosion or dilatation, or even a simulation of weak pressure by using OpenCV library only for image loading/saving, 
          all proocessing functions had to be created by ourselves.<br />
          The main tools used during this work was convolutions and interpolations.<br />
          <img src={un} alt="3D scene" ></img>
          <img src={quatre} alt="3D scene" ></img>
          </p>
        </div>
        </div>

{/* Fifth project #5 */}
        <div className="containerproject">
        <div id="box" className="four">
        <p id="project">
          <p id="title">
          Creation of a 3D scene (1st year Master's project)<br /> <FaPython color="#04B480" />
          </p>
          Manipulation of 3D objects: animations, hierarchy, illumination, physical rendering: ray casting, ray tracing and ray marching 
          to create different 3D scenes. <br /> 
          <img src={scene4} alt="3D scene" ></img>
          <video src={vid4} alt="3D scene" ></video>
          <img src={scene2} alt="3D scene" ></img>
          <img src={s3} alt="3D scene" ></img>
          <img src={s1} alt="3D scene" ></img>
          </p>
        </div>
        </div>



        </div>
      </div>
    </section>
  );
}