import React from "react";

import "/Users/isee/PERSO/mon-app-portfolio/src/CSS/footer.css";

export default function Footer() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <p id="app"> &copy; Isée Besombes EI 2022</p>
      </header>
    </div>
  );
}