import '/Users/isee/PERSO/mon-app-portfolio/src/CSS/App.css';
import React from "react";
import About from "../components/About";
import Contact from "../components/Contact";
import Education from "../components/Education";
import math from '/Users/isee/PERSO/mon-app-portfolio/src/image/bannerS.png';
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "/Users/isee/PERSO/mon-app-portfolio/src/CSS/header.css";
import Projects from "../components/Projects";
// import Skills from "./components/Skills";


export default function App() {
  return (
    <main>
    {/* <Header/> */}
    <Navbar />

    <About />
    <Projects />
    <Education/>
    <Contact />

    <Footer/>

    </main>
  );
}