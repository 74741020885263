import "/Users/isee/PERSO/mon-app-portfolio/src/CSS/about.css";
import me from '/Users/isee/PERSO/mon-app-portfolio/src/image/pp.jpg';

// import {FaLaughBeam} from 'react-icons/fa'
// import {SiHey} from 'react-icons/si'

export default function About() {
    return (
        <section id="about">
            <div className="container">
              <h1 id="about" className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
                Hi, I'm Isée Besombes. 
              </h1>
            </div>
            <div className="container">
                <p id="about" className="mb-8 leading-relaxed">
                Since my earliest childhood I am passionated about mathematics.<br/>
                {/* I consistently loved counting and classifying any of the shells I could pick at the beach, or marbles I had. I also remember discovering 
                multiplications myself by wanting to know how many grains of salt I put in my pasta when shaking the saltcellar 5 times considering 
                that only 1 grain goes through each hole at each jolt. <br/>
                I always wanted to express the things in a logical way and each math 
                class I had gave me this opportunity!<br/> */}
                That is why I naturally opted for math as a field of work. <br/>
                <br/>
                Today, thanks to my background in fundamental mathematics along with programming and the modeling of concepts of applied mathematics, 
                I am able to deal with many problems by having both the theoretical knowledge and the ability to implement it with different tools.<br/>
                My principal fields of work are image processing, modelisation, machine/deep learning 
                {/* (scikit-learn, pytorch, tensorflow) */}
                , python GUI, 
                computer vision.
                </p>
                <img id="about" src={me} alt="Isee Besombes"></img>
            </div>
        </section>
      );
    }