import "/Users/isee/PERSO/mon-app-portfolio/src/CSS/education.css";
import Gradient from 'rgt'

import miai from '/Users/isee/PERSO/mon-app-portfolio/src/image/MIAI.jpeg';
import ecmi from '/Users/isee/PERSO/mon-app-portfolio/src/image/ecmi.jpg';
import ens from '/Users/isee/PERSO/mon-app-portfolio/src/image/logoensimag.png';
import uga from '/Users/isee/PERSO/mon-app-portfolio/src/image/logoUGA2.png';
import IF from '/Users/isee/PERSO/mon-app-portfolio/src/image/if.png';
import scikit from '/Users/isee/PERSO/mon-app-portfolio/src/image/scikit.png';
import inria from '/Users/isee/PERSO/mon-app-portfolio/src/image/inria.png';


export default function Education() {
  return (
    <section id="education">
      <h1 id="educ"><Gradient dir="left-to-right" from="rgba(3, 156, 128  ,1)" to="rgba(5, 233, 165, 1)"> EDUCATION </Gradient></h1>

{/* Section dedicated to the master degree */}
      <div className="containerproject">
      <img id="educ" src={uga} alt="uga"></img>
      <img id="educ" src={ens} alt="ensimag"></img>
      <p> <p id="larger">Master of Science in Industrial and Applied Mathematics at Grenoble Alpes University & Grenoble INP Ensimag.</p>
      Main courses: 
      <ul>
            <li>Image processing: filtering, blurring/deblurring, contours and features detection, geophysical imaging, wavelets;</li>
            <li>Numerical Optimization: transports, costs, shapes and topologies;</li>
            <li>Probability & Statistics;</li>
            <li>Data Science;</li>
            <li>Geometric modeling;</li>
            <li>3D Graphics: rendering methods, animations, deformartion;</li>
            <li>Modeling with the variational method: finite element method, PDE;</li>
            <li>Programming: C++, Python.</li>
      </ul>
      </p>
      <img id="educ" src={miai} alt="miai"></img>
      <img id="educ" src={ecmi} alt="ecmi"></img>
      </div>

{/* Section about the trainings I made outside of the master*/}
      <div className="containerproject">
      <p><p id="larger">Machine learning in Python with scikit-learn</p> Build predictive models with scikit-learn and gain a practical understanding of the strengths and limitations of machine learning.<br />
      Course followed on may 2022 (MOOC).</p>
      <img id="educ" src={scikit} alt="scikit-learn"></img>
      <img id="educ" src={inria} alt="inria"></img>
      </div>

{/* Section about my bachelor degree */}
      <div className="containerproject"> 
      <img id="educ" src={uga} alt="uga"></img>
      <img id="educ" src={IF} alt="institut-fourier"></img>
      <p> <p id="larger">Bachelor in pure Mathematics at Grenoble Alpes University.</p>
      Bachelor focused on fondamental mathematics with some options in informatics/ applied mathematiques.<br />
      Main courses:
      <ul>
            <li>Algreba;</li>
            <li>Topology & geometry;</li>
            <li>Probability & Statistics;</li>
            <li>Arithmetic, integration & Fourier Series;</li>
            <li>Differential Calculus;</li>
            <li>Algorithmic: python programming;</li>
            <li>Computer-assisted mathematics;</li>
            <li>Numerical modelisation.</li>
      </ul>
      </p>
      </div>
    </section>
);
}