import React from "react";

import "/Users/isee/PERSO/mon-app-portfolio/src/CSS/Navbar.css";

export default function Navbar() {
  return (
    <section>
    <nav>
      <a id="nav" href="#about">About</a>
      <a id="nav" href="#projects">Portfolio</a>
      <a id="nav" href="#education">Education</a>
      <a id="nav" href="#contact">Contact</a>
    </nav>
    </section>
  );
}